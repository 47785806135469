import {Injectable} from "@angular/core";
import {clone} from "lodash-es";

/**
 * Facilitates iterating through potentially virtually endless arrays.
 * draw() can be called as many times as desired. There is no way of
 * indicating exhaustion.
 */
export interface Drawer<T> {
  draw: () => T;
}

@Injectable()
export class ShuffleService {
  /**
   * Shuffle an array (potentially) in place using Fisher-Yates.
   */
  shuffle<T>(deck: T[]): T[] {
    let cix = deck.length;
    while (0 !== cix) {
      let rix = Math.floor(Math.random() * cix);
      cix -= 1;
      let bucket = deck[cix];
      deck[cix] = deck[rix];
      deck[rix] = bucket;
    }
    return deck;
  }

  /**
   * Return a {Drawer} that can be used to randomly choose elements from a deck.
   * This will always complete an entire deck before potentially reusing it.
   * To allow the same element to potentially be selected multiple times within
   * the same deck iteration, use drawFromRandomly instead.
   * @param deck a potentially finite deck that will be appropriately extended as needed
   */
  drawFrom<T>(deck: T[]): Drawer<T> {
    let ldeck = this.shuffle(clone(deck));
    let ncards = ldeck.length;
    let mark = 0;
    let shuffler = this;
    return {
      draw(): T {
        if (mark >= ncards) {
          ldeck = shuffler.shuffle(ldeck);
          mark = 0;
        }
        return ldeck[mark++];
      }
    };
  }

  drawFromRandomly<T>(deck: T[]): Drawer<T> {
    return {
      draw(): T {
        return deck[Math.floor(Math.random() * deck.length)];
      }
    };
  }
}
