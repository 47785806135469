<div class="header" *ngIf="quiz">
  <div class="pitcher-info">
    <h1>
      <span>Pitcher: {{quiz.pitcher.name}} ({{quiz.pitcher.handedness}})</span>
      <span *ngIf="mark >= 0">Pitch #{{mark + 1}} of {{quiz.pitches.length}}</span>
    </h1>
  </div>
</div>

<div class="loading vcs">
  <mat-progress-bar mode="videosLoaded > 0 ? 'determinate' : 'query'"
                    *ngIf="videosLoaded < allNeededVideos.length"
                    [value]="videosLoaded / allNeededVideos.length * 100"></mat-progress-bar>
  <button class="play-ball" mat-flat-button color="accent" (click)="throwFirstPitch()"
          [disabled]="videosLoaded < allNeededVideos.length"
          *ngIf="state === 'idle'">play ball</button>
</div>

<div class="video vcs">
  <ng-container *ngFor="let url of allNeededVideos">
    <video eyeballPitch [src]="url" playsinline muted [class.hidden]="!activePitch || activePitch.videoUrl !== url"></video>
  </ng-container>
</div>

<div class="response-buttons">
  <button mat-flat-button color="primary" *ngFor="let pflav of ALL_PITCH_FLAVORS"
          [disabled]="state !== 'inFlight' || !isPitchFlavorPossible(pflav)"
          [class.revealed-correct]="state === 'feedbacking' && pflav === activePitch?.flavor"
          (click)="answer(pflav)">
    {{pflav}}
  </button>
</div>
