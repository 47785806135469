export type PitchFlavor = "fastball" | "curve" | "slider" | "changeup" | "cutter";
export const ALL_PITCH_FLAVORS: PitchFlavor[] = ["fastball", "curve", "slider", "changeup", "cutter"];
export const PITCH_ICONS: {[pflav: string]: string} = {
  fastball: "looks_one",
  curve: "looks_two",
  slider: "looks_3",
  changeup: "looks_4",
  cutter: "looks_5",
};
export type Handedness = "L" | "R";

export interface Pitch {
  videoUrl: string;
  flavor: PitchFlavor;
  duration: number; // milliseconds
}

export interface Pitcher {
  name: string;
  handedness: Handedness;
  repertoire: Pitch[];
}

export function couldThrow(pitcher: Pitcher, flav: PitchFlavor): boolean {
  return !!pitcher.repertoire.find(p => p.flavor === flav);
}
