import {Component, OnDestroy} from "@angular/core";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Result, ResultsService} from "./results.service";
import {Router} from "@angular/router";
import {QuizService} from "../quiz/quiz.service";
import {QuizParams} from "../quiz-params/quiz-params.component";

@UntilDestroy()
@Component({
  selector: "eyeball-results",
  templateUrl: "results.component.html",
  styleUrls: ["results.component.scss"],
})
export class ResultsComponent implements OnDestroy {
  private allResults: Result[] = [];
  private mark = 0;
  activeResult?: Result;
  totalScore = 0;

  constructor(private resulter: ResultsService,
              private router: Router,
              private quizzer: QuizService) {
    this.resulter.watch().pipe(untilDestroyed(this))
      .subscribe(ar => {
        this.allResults = ar;
        this.setMark(this.allResults.length - 1);
      });
  }

  ngOnDestroy() {
  }

  private setMark(mark: number): void {
    this.mark = mark;
    this.totalScore = 0;
    if (this.mark >= 0 && this.mark < this.allResults.length) {
      this.activeResult = this.allResults[this.mark];
      this.activeResult.answers.forEach(answer => {
        this.totalScore += answer.score;
      });
    } else {
      this.activeResult = undefined;
    }
  }

  canMoveForward(): boolean {
    return this.mark >= 0 && (1 + this.mark) < this.allResults.length;
  }

  canMoveBackward(): boolean {
    return this.mark > 0;
  }

  moveForward(): void {
    this.setMark(this.mark + 1);
  }

  moveBackward(): void {
    this.setMark(this.mark - 1);
  }

  vaporizeAll(): void {
    this.resulter.clear();
    this.router.navigate(["/"]);
  }

  retry(): void {
    if (!this.activeResult) {
      throw new Error("retry what?");
    }
    let qp: QuizParams = {
      pitcher: this.activeResult.quiz.pitcher,
      pitchCount: this.activeResult.quiz.pitches.length,
    };
    this.quizzer.generateQuiz(qp).then(() => this.router.navigate(["/quiz"]));
  }
}
