import {Injectable} from "@angular/core";
import * as localForage from "localforage";

@Injectable()
export class SinkService {
  private lf: any;

  constructor() {
    this.lf = localForage.createInstance({
      name: "eyeball",
      storeName: "eyeball",
    });
  }

  stash<T>(key: string, val: T): Promise<void> {
    return this.lf.setItem(key, val);
  }

  retrieve<T>(key: string): Promise<T> {
    return this.lf.getItem(key);
  }

  forget(key: string): Promise<void> {
    return this.lf.removeItem(key);
  }
}
