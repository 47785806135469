import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {QuizParams, QuizParamsComponent} from "../quiz-params/quiz-params.component";
import {QuizService} from "../quiz/quiz.service";

@Component({
  selector: "eyeball-home",
  templateUrl: "home.component.html",
  styleUrls: ["home.component.scss"],
})
export class HomeComponent {
  constructor(private router: Router,
              private dialoger: MatDialog,
              private quizzer: QuizService) {
  }

  popNewQuizDialog(): void {
    let dlog = this.dialoger.open<QuizParamsComponent, QuizParams>(QuizParamsComponent, {
      width: "75%",
      panelClass: "eyeball-dialog-panel",
    });
    dlog.afterClosed().subscribe(qp => {
      if (qp) {
        this.quizzer.generateQuiz(qp).then(() => this.router.navigate(["/quiz"]));
      }
    });
  }
}

