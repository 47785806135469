import {AfterViewInit, Component, ElementRef, OnDestroy, QueryList, ViewChildren} from "@angular/core";
import {Quiz, QuizService} from "./quiz.service";
import {ALL_PITCH_FLAVORS, couldThrow, Pitch, PitchFlavor} from "../pitch/pitch";
import {differenceInMilliseconds} from "date-fns";
import {Answer, Result, ResultsService} from "../results/results.service";
import {Router} from "@angular/router";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {PitchDirective} from "../pitch/pitch.directive";

type QuizState = "idle" | "waiting" | "windingUp" | "inFlight" | "feedbacking";

const TAKE_PENALTY = -20;
const MISS_PENALTY = -50;
const CORRECT_BASE = 20;
const PERFECT_SCORE = 100;

/**
 * The number of milliseconds to show player the correct answer.
 */
const FEEDBACK_MILLIS = 3000;

@UntilDestroy()
@Component({
  selector: "eyeball-quiz",
  templateUrl: "quiz.component.html",
  styleUrls: ["quiz.component.scss"],
})
export class QuizComponent implements AfterViewInit, OnDestroy {
  ALL_PITCH_FLAVORS = ALL_PITCH_FLAVORS;
  state: QuizState = "idle";
  quiz?: Quiz;
  private answers?: Answer[];
  // how many pitches deep into the quiz are we?
  mark = -1;
  activePitch?: Pitch;
  windingUp = false;
  windupNotification?: string;
  pitchThrownAt?: Date;
  @ViewChildren(PitchDirective) videoElements?: QueryList<PitchDirective>;
  // reset on each pitch, if this fires, the player waited too long to answer
  timeout?: number;
  // this is an ugly performance hack in order to get all the videos preloaded.
  // contains every pitch that will be used in the quiz.
  allNeededVideos: string[] = [];
  videoLoadings$: Promise<void>[] = [];
  videosLoaded = 0;

  constructor(private quizzer: QuizService,
              private resulter: ResultsService,
              private router: Router) {
  }

  ngOnDestroy() {
  }

  ngAfterViewInit(): void {
    if (!this.videoElements) {
      throw new Error("need video element refs here");
    }
    this.videoElements.changes.pipe(untilDestroyed(this))
      .subscribe((videls: QueryList<PitchDirective>) => {
        this.videoLoadings$ = [];
        this.videosLoaded = 0;
        videls.forEach(videl => {
          this.videoLoadings$.push(videl.load().then(() => {
            this.videosLoaded++;
          }));
        });
      });
    this.startNewQuiz();
  }

  /**
   * Initiate a brand new quiz. May be called when a quiz is currently opened,
   * but caller is responsible for cleaning up and confirming if needed.
   */
  startNewQuiz(): void {
    this.state = "idle";
    this.mark = -1;
    this.answers = [];
    this.quizzer.activeQuiz().then(quiz => {
      this.quiz = quiz;
      this.allNeededVideos = [];
      this.quiz.pitches.forEach(pitch => {
        if (!this.allNeededVideos.find(url => url === pitch.videoUrl)) {
          this.allNeededVideos.push(pitch.videoUrl);
        }
      });
    }, () => {
      // most likely reason we're here is a browser reload on the quiz page,
      // bail back to home page
      this.router.navigate(["/"]);
    });
  }

  throwFirstPitch(): void {
    this.prepareForPitch();
  }

  /**
   * Prepare to initiate next pitch in quiz. If there are no more pitches to throw,
   * the quiz completes and we enter feedback state.
   */
  prepareForPitch(): void {
    if (!this.quiz) {
      throw new Error("can't prepare for pitch with no quiz");
    }
    if (this.mark >= this.quiz.pitches.length) {
      this.finish();
      return;
    }
    this.activePitch = this.quiz.pitches[++this.mark];
    this.state = "waiting";
    this.initiatePitch();
  }

  /**
   * Returns if a given {PitchFlavor} is possible, given the repertoire of the
   * current pitcher. In general, calling functions like this from templates
   * where change detection can be invoked is a bad idea, because change detection
   * can call them zillions of times. So for a larger set of possibilities, a
   * precomputed hash table would be a potential win, but with only a handful of
   * pitch types, that's likely premature optimization.
   */
  isPitchFlavorPossible(flav: PitchFlavor): boolean {
    return !!this.quiz && couldThrow(this.quiz.pitcher, flav);
  }

  private activeVideoElement(): PitchDirective | undefined {
    if (!this.activePitch || !this.videoElements) {
      return undefined;
    }
    let activeUrl = this.activePitch.videoUrl;
    return this.videoElements.find(ve => ve.matchesUrl(activeUrl));
  }

  /**
   * Prepare to throw pitch. Can be either manually or automatically triggered,
   * but must be called from "waiting" state. No further action is required -
   * this will set in motion a chain of events including throwing the pitch
   * and waiting for the answer.
   */
  initiatePitch(): void {
    if (!this.videoElements) {
      throw new Error("need video element refs");
    }
    if (!this.activePitch) {
      throw new Error("need active pitch here");
    }
    let videoElem = this.activeVideoElement();
    if (!videoElem) {
      throw new Error("can't find right video element");
    }
    // TODO: this could/should be centralized, and technically we should not be writing to activePitch
    this.activePitch.duration = Math.round(videoElem.duration() * 1000);
    videoElem.rewind();
    videoElem.play().then(() => {
      this.pitchThrown();
    });
  }

  /**
   * Throw the pitch. This starts the response clock.
   */
  private pitchThrown(): void {
    if (!this.activePitch) {
      throw new Error("need active pitch here");
    }
    this.pitchThrownAt = new Date();
    this.state = "inFlight";
    this.timeout = setTimeout(() => {
      delete this.timeout;
      this.answer(undefined);
    }, this.activePitch.duration);
  }

  /**
   * Receive and record an answer.
   */
  answer(flavor?: PitchFlavor): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      delete this.timeout;
    }
    let velem = this.activeVideoElement();
    if (velem) {
      velem.pause();
    }
    if (!this.pitchThrownAt) {
      throw new Error("pitchThrownAt undefined");
    }
    if (!this.quiz) {
      throw new Error("no quiz");
    }
    if (!this.answers) {
      throw new Error("no answers list");
    }
    if (!this.activePitch) {
      throw new Error("how can we be answering a ghost pitch?");
    }
    if (this.answers.length !== this.mark) {
      throw new Error(`mark ${this.mark}, but ${this.answers.length} answers`);
    }
    // calculate score on this answer.
    // ordinarily i would love to factor this out into a separate method, but
    // doing so would involve redesigning some combination of Pitch / Answer / Quiz
    // in order to have all the available information available. i can't think of
    // a clean way to do that without saddling them with needless baggage that isn't
    // always defined.
    let responseMillis = differenceInMilliseconds(new Date(), this.pitchThrownAt);
    let score: number;
    if (!flavor) {
      // player did not answer, lose TAKE_PENALTY
      score = TAKE_PENALTY;
    } else if (flavor !== this.activePitch.flavor) {
      // player guessed wrong, lose MISS_PENALTY
      score = MISS_PENALTY;
    } else {
      // player guessed right, scale speed of response linearly so that a
      // correct response right at the buzzer gives us CORRECT_BASE, and
      // an instantaneous right answer gets PERFECT_SCORE
      score = Math.round((1 - responseMillis / this.activePitch.duration)
        * (PERFECT_SCORE - CORRECT_BASE) + CORRECT_BASE);
    }
    let answer: Answer = {
      flavor,
      responseMillis,
      score,
    };
    this.answers.push(answer);
    this.giveFeedback();
  }

  /**
   * Provide feedback to the player showing how they did on the last pitch.
   */
  giveFeedback(): void {
    this.state = "feedbacking";
    setTimeout(() => {
      if (!this.quiz) {
        throw new Error("can't feedback nonexistent quiz");
      }
      if (this.mark >= this.quiz.pitches.length - 1) {
        this.finish();
      } else {
        this.prepareForPitch();
      }
    }, FEEDBACK_MILLIS);
  }

  /**
   * Calculates score for a given answer
   * TODO: improve algorithm?
   */
  score(pitch: Pitch, answer: Answer, maxMillis: number): number {
    if (answer.flavor) {
      // player did not answer, lose TAKE_PENALTY
      return TAKE_PENALTY;
    }

    if (answer.flavor !== pitch.flavor) {
      // player guessed wrong, lose MISS_PENALTY
      return -MISS_PENALTY;
    }

    // player guessed right, scale speed of response linearly so that a
    // correct response right at the buzzer gives us CORRECT_BASE, and
    // an instantaneous right answer gets PERFECT_SCORE
    return Math.round((1 - answer.responseMillis / maxMillis)
      * (PERFECT_SCORE - CORRECT_BASE) + CORRECT_BASE);
  }

  finish(): void {
    delete this.activePitch;
    if (!this.quiz || !this.answers) {
      throw new Error("trying to finish nonexistent quiz");
    }
    let result: Result = {
      quiz: this.quiz,
      answers: this.answers,
    };
    this.resulter.push(result);
    this.router.navigate(["/results"]);
  }
}
