<div class="outer">
  <div class="buttons">
    <button mat-flat-button color="primary" (click)="popNewQuizDialog()">new quiz</button>
    <button mat-flat-button color="primary" [routerLink]="['/results']">show results</button>
    <button mat-flat-button color="primary" [routerLink]="['/howto']">instructions</button>
  </div>

  <div class="logo">
    <img src="assets/logo.png">
  </div>

  <div class="credits">
    <span>by Sam Bryant</span>
    <span>Overlake Class of 2020 Senior Project</span>
  </div>
</div>
