import {Directive, ElementRef} from "@angular/core";

@Directive({
  selector: "[eyeballPitch]",
})
export class PitchDirective {
  private loadingListener?: () => void;

  constructor(private elref: ElementRef<HTMLVideoElement>) {
  }

  matchesUrl(url: string): boolean {
    return this.elref.nativeElement.src.endsWith(url);
  }

  duration(): number {
    return this.elref.nativeElement.duration;
  }

  load(): Promise<void> {
    if (this.loadingListener) {
      this.elref.nativeElement.removeEventListener("canplaythrough", this.loadingListener);
    }
    let rv = new Promise<void>(resolve => {
      this.loadingListener = () => {
        if (this.loadingListener) {
          this.elref.nativeElement.removeEventListener("canplaythrough", this.loadingListener);
        }
        resolve();
      };
      this.elref.nativeElement.addEventListener("canplaythrough", this.loadingListener);
    });
    this.elref.nativeElement.load();
    return rv;
  }

  rewind(): void {
    this.elref.nativeElement.currentTime = 0;
  }

  play(): Promise<void> {
    return this.elref.nativeElement.play();
  }

  pause(): void {
    return this.elref.nativeElement.pause();
  }
}
