<h1>Instructions</h1>

<p>Select a pitcher and number of pitches. When you're ready, select "Play Ball". Watch the pitches closely and select the pitch. Be careful! Incorrect answers are penalized, but so are slow answers. Good Luck. </p>

<div class="xc">
  <button mat-flat-button routerLink="/" color="primary">
    <mat-icon>home</mat-icon>
    home
  </button>
</div>
