import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {HomeComponent} from "./home/home.component";
import {QuizComponent} from "./quiz/quiz.component";
import {ResultsComponent} from "./results/results.component";
import {HowtoComponent} from "./howto/howto.component";

const routes: Routes = [
  {path: "", component: HomeComponent},
  {path: "quiz", component: QuizComponent},
  {path: "results", component: ResultsComponent},
  {path: "howto", component: HowtoComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
