import {Injectable} from "@angular/core";
import {Quiz} from "../quiz/quiz.service";
import {BehaviorSubject, Observable} from "rxjs";
import {SinkService} from "../sink/sink.service";
import {PitchFlavor} from "../pitch/pitch";

/**
 * The player's response to a pitch: how it was characterized, and how quickly
 * the player reacted. A flavor of undefined indicates a failure to answer
 * (perhaps caused by a timeout).
 */
export interface Answer {
  flavor?: PitchFlavor;
  responseMillis: number;
  score: number;
}

const RESULTS_KEY = "results";

export interface Result {
  quiz: Quiz;
  answers: Answer[];
}

@Injectable()
export class ResultsService {
  allResults$ = new BehaviorSubject<Result[]>([]);

  constructor(private sink: SinkService) {
    this.sink.retrieve<Result[]>(RESULTS_KEY).then(ar => {
      if (ar) {
        this.allResults$.next(ar);
      }
    });
  }

  watch(): Observable<Result[]> {
    return this.allResults$;
  }

  peek(): Result[] {
    return this.allResults$.value;
  }

  clear(): Promise<void> {
    this.allResults$.next([]);
    return this.sink.forget(RESULTS_KEY);
  }

  push(result: Result): void {
    // there is a case to be made for cloning here, so that consumers holding
    // old object references aren't surprised.
    let xar = this.peek();
    xar.push(result);
    this.allResults$.next(xar);
    this.sink.stash(RESULTS_KEY, xar);
  }

}

