import {Component} from "@angular/core";

@Component({
  selector: "eyeball-howto",
  templateUrl: "howto.component.html",
  styleUrls: ["howto.component.scss"]
})
export class HowtoComponent {

}
