import {Component, Input} from "@angular/core";
import {ALL_PITCH_FLAVORS, couldThrow, PITCH_ICONS, Pitcher, PitchFlavor} from "../pitch/pitch";

@Component({
  selector: "eyeball-pitcher",
  templateUrl: "pitcher.component.html",
  styleUrls: ["pitcher.component.scss"],
})
export class PitcherComponent {
  @Input() pitcher?: Pitcher;
  ALL_PITCH_FLAVORS = ALL_PITCH_FLAVORS;
  PITCH_ICONS = PITCH_ICONS;

  couldThrow(pflav: PitchFlavor): boolean {
    return !!this.pitcher ? couldThrow(this.pitcher, pflav) : false;
  }
}
