import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";

import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatDialogModule} from "@angular/material/dialog";
import {HomeComponent} from "./home/home.component";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {QuizService} from "./quiz/quiz.service";
import {ShuffleService} from "./shuffle/shuffle.service";
import {QuizParamsComponent} from "./quiz-params/quiz-params.component";
import {QuizComponent} from "./quiz/quiz.component";
import {ResultsComponent} from "./results/results.component";
import {SinkService} from "./sink/sink.service";
import {ResultsService} from "./results/results.service";
import {MatFormFieldModule} from "@angular/material/form-field";
import {HttpClientModule} from "@angular/common/http";
import {MatIconModule} from "@angular/material/icon";
import {PitcherComponent} from "./pitcher/pitcher.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {PitchDirective} from "./pitch/pitch.directive";
import {HowtoComponent} from "./howto/howto.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    QuizParamsComponent,
    QuizComponent,
    ResultsComponent,
    PitcherComponent,
    PitchDirective,
    HowtoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
  ],
  providers: [
    QuizService,
    ShuffleService,
    SinkService,
    ResultsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
