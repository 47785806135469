<h2 mat-dialog-title>new quiz settings</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-select [formControl]="pitcher" placeholder="pitcher">
      <mat-select-trigger>
        {{pitcher.value.name}}
      </mat-select-trigger>
      <mat-option *ngFor="let pitcher of availablePitchers" [value]="pitcher">
        <eyeball-pitcher [pitcher]="pitcher"></eyeball-pitcher>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-select [formControl]="pitchCount" placeholder="pitch count">
      <mat-option *ngFor="let npitches of availableCounts" [value]="npitches">
        {{npitches}}
      </mat-option>
    </mat-select>
    <mat-label matSuffix>pitches</mat-label>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="dismiss()" [disabled]="!form.valid">start quiz</button>
</mat-dialog-actions>
