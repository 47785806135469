import {Component} from "@angular/core";
import {couldThrow, Pitcher, PitchFlavor} from "../pitch/pitch";
import {MatDialogRef} from "@angular/material/dialog";
import {QuizService} from "../quiz/quiz.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

export interface QuizParams {
  pitcher: Pitcher;
  pitchCount: number;
  maxMillis?: number;
}

@Component({
  selector: "eyeball-quiz-params",
  templateUrl: "quiz-params.component.html",
  styleUrls: ["quiz-params.component.scss"],
})
export class QuizParamsComponent {
  availablePitchers: Pitcher[] = [];
  availableCounts = [5, 10, 20];
  pitcher = new FormControl("", Validators.required);
  pitchCount = new FormControl(10, Validators.required);
  form = new FormGroup({pitcher: this.pitcher, pitchCount: this.pitchCount});

  constructor(private dlogref: MatDialogRef<QuizParamsComponent>,
              private quizzer: QuizService) {
    quizzer.allAvailablePitchers().then(pitchers => this.availablePitchers = pitchers);
  }

  chooseRandomPitcher(): void {
    this.pitcher.setValue(this.availablePitchers[Math.floor(Math.random() * this.availablePitchers.length)]);
  }

  canThrow(pitcher: Pitcher, pitch: PitchFlavor): boolean {
    return couldThrow(pitcher, pitch);
  }

  dismiss(): void {
    let rv: QuizParams = {
      pitcher: this.pitcher.value,
      pitchCount: this.pitchCount.value,
    };
    this.dlogref.close(rv);
  }
}
