<mat-toolbar>
  <mat-toolbar-row>
    <button mat-icon-button color="primary" (click)="moveBackward()"
            [disabled]="!canMoveBackward()">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button mat-icon-button color="primary" [routerLink]="['/']">
      <mat-icon>home</mat-icon>
    </button>
    <div class="pusher">results</div>
    <button mat-icon-button color="primary" (click)="vaporizeAll()">
      <mat-icon>delete_forever</mat-icon>
    </button>
    <button mat-icon-button color="primary" (click)="moveForward()"
            [disabled]="!canMoveForward()">
      <mat-icon>navigate_next</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="main" *ngIf="activeResult; else nothingToReport">
  <span class="title">actual</span>
  <span class="title">you said</span>
  <span class="title">speed</span>
  <span class="title">score</span>

  <ng-container *ngFor="let answer of activeResult.answers; let i = index">
    <span class="tjc" [innerText]="activeResult.quiz.pitches[i].flavor"></span>
    <ng-container *ngIf="answer.flavor">
      <span class="tjc" [innerText]="answer.flavor"></span>
      <span class="tjr">{{answer.responseMillis}}/{{activeResult.quiz.pitches[i].duration}} ms</span>
    </ng-container>
    <span *ngIf="!answer.flavor" class="take tjc">taken</span>
    <span class="tjr" [innerText]="answer.score"></span>
  </ng-container>

  <span class="total-title">total score:</span>
  <span class="tjr">{{totalScore}}</span>

  <div class="xc retry">
    <button mat-flat-button color="accent" (click)="retry()"><mat-icon>redo</mat-icon>retry</button>
  </div>
</div>

<ng-template #nothingToReport>
  <p>nothing to report</p>
</ng-template>
