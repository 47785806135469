import {Injectable} from "@angular/core";
import {Pitch, Pitcher} from "../pitch/pitch";
import {HttpClient} from "@angular/common/http";
import {ShuffleService} from "../shuffle/shuffle.service";
import {QuizParams} from "../quiz-params/quiz-params.component";

/**
 * A unit of interaction with the player. All pitches are delivered by the same
 * pitcher.
 */
export interface Quiz {
  pitcher: Pitcher;
  pitches: Pitch[];
}

@Injectable()
export class QuizService {
  private allPitchers: Pitcher[] = [];
  private ready$: Promise<void>;
  public quiz$?: Promise<Quiz>;

  constructor(private http: HttpClient, private shuffler: ShuffleService) {
    this.ready$ = Promise.all([
      http.get<Pitcher[]>("assets/pitchers.json").toPromise(),
    ]).then(([pitchers]) => {
      this.allPitchers = pitchers;
    });
  }

  /**
   * Callers must not modify the returned array.
   */
  allAvailablePitchers(): Promise<Pitcher[]> {
    return this.ready$.then(() => this.allPitchers);
  }

  /**
   * Returns a new quiz.
   *
   * Since a pitcher can throw the same pitch multiple times in a quiz, there is
   * no requirement to actually have enough pitches in the corpus to fill the quiz.
   */
  public generateQuiz(params: QuizParams): Promise<Quiz> {
    this.quiz$ = this.ready$.then(() => {
      let possiblePitches = this.possiblePitchesBy(params.pitcher);
      let drawer = this.shuffler.drawFromRandomly(possiblePitches);
      let rv: Quiz = {
        pitcher: params.pitcher,
        pitches: [],
      };
      for (let i = 0; i < params.pitchCount; ++i) {
        rv.pitches.push(drawer.draw());
      }
      return rv;
    });
    return this.quiz$;
  }

  public activeQuiz(): Promise<Quiz> {
    return this.quiz$ || Promise.reject("no quiz yet");
  }

  /**
   * All private methods assume that ready$ has resolved.
   */

  /**
   * Returns all possible pitches that could be thrown by a given pitcher
   */
  private possiblePitchesBy(pitcher: Pitcher): Pitch[] {
    return pitcher.repertoire;
  }
}
